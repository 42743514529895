
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/p/[projectId]",
      function () {
        return require("private-next-pages/p/[projectId].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/p/[projectId]"])
      });
    }
  