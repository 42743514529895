import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Container,
  HStack,
  Link,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  Flex,
  IconButton,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useProjectBySlug,
  useProjectStatsBySlug,
} from '../techRaffles/hooks/project'
import ProjectOverviewHeader from './components/ProjectOverviewHeader'
import ProjectOverviewRaffles from './components/ProjectOverviewRaffles'
import Footer from './layouts/Footer'
import Header from './layouts/Header'
import { projectNavigationType } from '../techRaffles/types'
import ProjectCommunityMembers from './components/ProjectCommunityMembers'
import { useLocation } from 'react-use'
import ProjectCollections from './components/ProjectCollections'
import { ProfileCard } from '../rewards/components/ProfileCard'
import { BsFillPatchCheckFill, BsTwitter } from 'react-icons/bs'
import { FaDiscord } from 'react-icons/fa'
import { TbWorld } from 'react-icons/tb'
import { projectType } from '../techRaffles/types'
import { ProfilePicture } from './components/ProfilePicture'
import ProjectNavigationButtons from './components/ProjectNavigationButtons'
import { ContentTabs } from '../rewards/components/ContentTabs'
import { StatCard } from '../rewards/components/StatCard'
import { PiStackSimple, PiUsers, PiStar, PiLightning } from 'react-icons/pi'
import { HiOutlineTicket } from 'react-icons/hi'
import { formatFloatForDisplay } from '../../utils/utils'
import ProjectOTCDeals from './components/ProjectOTCDeals'


const communityPageTabs: { label: string; value: projectNavigationType }[] = [
  { label: 'Info', value: 'INFO' },
  { label: 'Raffles', value: 'RAFFLES' },
  {
    label: 'Community Members',
    value: 'MEMBERS',
  },
  {
    label: 'OTC deals',
    value: 'OTC',
  }
]
const rafflesTab = communityPageTabs.find(t => t.value === 'RAFFLES')!

const DAppCommunityOverview = () => {
  const projectRes = useProjectBySlug()
  const projectStatRes = useProjectStatsBySlug('ALLTIME')
  const [selectedNavigation, setSelectedNavigation] =
    useState(rafflesTab)

  const isLoading = useMemo(() => projectRes.isLoading, [projectRes.isLoading])
  const location = useLocation()
  useEffect(() => {
    const query = location.hash?.split('#')[1]
    if (query && query !== selectedNavigation?.value) {
      const queryExists = communityPageTabs.find(t => t.value === query)
      if (queryExists) setSelectedNavigation(queryExists)
    }
  }, [location.hash, selectedNavigation])

  const projectDescription =
    "JellyCo is a dynamic project where community and utility thrive. Rascals offers a range of exciting benefits such as staking for daily $JELLY rewards, best in class alpha callers, reduced fees for raffles, community raids, VIP access to IRL events, premium merch, and unrivaled raffles featuring high-value NFTs and cash prizes. With future plans including discounted $JELLY deposits on our products, lootbox profit sharing, treasure hunts, and more. We're committed to providing ongoing value to our community. Gamerooms offer a lucrative revenue share when burnt & staking multipliers when not"

  const ProjectInfo = () => {
    const bio = projectRes.data?.bio
    return (
      <VStack w='full' gap='10'>
        {bio && (
          <VStack w='full'>
            <Text
              fontSize='30px'
              mb='5'
              fontWeight='bold'
              alignSelf='flex-start'
            >
              About
            </Text>
            <Text px='20px' fontSize='20px' color='#7B7B7B'>
              {bio}
            </Text>
          </VStack>
        )}

        <VStack w='full'>
          <ProjectCollections />
        </VStack>
      </VStack>
    )
  }

  return (
    <Box color='#232323 !important' fontFamily='Inter !important'>
      {/* {isLoading && <ProjectOverviewHeaderSkeleton />} */}

      <>
        <Box
          height={['40rem']}
          w='100%'
          bgRepeat='no-repeat'
          bgSize='cover'
          background={
            !isLoading && projectRes?.data
              ? `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${
                  projectRes.data?.bannerUrl ?? ''
                }')`
              : ''
          }
          bgPos='center top'
          backgroundSize='cover!important'
          px='1rem'
          py={['.5rem', '1rem', '2rem']}
        >
          <Header theme='transparent' />
          <Container maxWidth='1440px'>
            {/* <Stack
                direction={['column', 'column', 'row', 'row']}
                mt={['4rem', '6.5rem']}
                justify='space-between'
                spacing={[5, 10, 0, 0]}
              >
                <HStack color='#fff' spacing={'1.25rem'}>
                  <ProfilePicture
                    imageUrl={projectRes.data.profilePictureUrl}
                    gradientstart={projectRes.data.gradientStart}
                    gradientend={projectRes.data.gradientEnd}
                    w='4rem'
                    h='4rem'
                    borderRadius='15px'
                  />
                  <Box>
                    <Text
                      color='#fff'
                      fontSize={['1rem', '1.875rem']}
                      fontWeight='600'
                    >
                      {projectRes.data.platformName}
                    </Text>
                    <HStack>
                      <TextprojectRes.data.verified  color='#fff'>{projectRes.data.communityName}</Text>
                      {&& (
                        <BsFillPatchCheckFill color='#fff' />
                      )}
                    </HStack>
                  </Box>
                </HStack>
                <Stack direction='row' spacing={[1, 0, 5]}>
                  {projectRes.data.twitterUserHandle && (
                    <Link
                      href={`https://twitter.com/${projectRes.data.twitterUserHandle}`}
                      target='_blank'
                    >
                      <IconButton
                        rounded='full'
                        bg='transparent'
                        aria-label='twitter'
                        _hover={{
                          bg: 'rgba(255, 255, 255, 0.2)',
                        }}
                        icon={<BsTwitter color='white' />}
                      />
                    </Link>
                  )}

                  {projectRes.data.magicEdenSlug && (
                    <Link
                      href={`https://magiceden.io/${projectRes.data.magicEdenSlug}`}
                      target='_blank'
                    >
                      <IconButton
                        aria-label='ME'
                        bg='transparent'
                        border='none'
                        _hover={{
                          bg: 'rgba(255, 255, 255, 0.2)',
                        }}
                        icon={'/icons/logo-me.svg'}
                      ></IconButton>
                    </Link>
                  )}

                  {projectRes.data.discordInviteLink && (
                    <Link
                      href={`${projectRes.data.discordInviteLink}`}
                      target='_blank'
                    >
                      <IconButton
                        border='none'
                        bg='transparent'
                        aria-label='discord'
                        rounded='full'
                        _hover={{
                          bg: 'rgba(255, 255, 255, 0.2)',
                        }}
                        icon={<FaDiscord color='white' />}
                      />
                    </Link>
                  )}

                  {projectRes.data.websiteUrl && (
                    <Link
                      href={`${projectRes.data.websiteUrl}`}
                      target='_blank'
                    >
                      <IconButton
                        aria-label='share'
                        bg='transparent'
                        border='none'
                        _hover={{
                          bg: 'rgba(255, 255, 255, 0.2)',
                        }}
                        icon={<TbWorld color='white' />}
                      />
                    </Link>
                  )}
                </Stack>
              </Stack> */}
            {/* <Flex mt={['2rem', '2rem', '5.75rem']} justify='space-between'>
                <Box
                  bg='rgba(255, 255, 255, 0.15)'
                  rounded='20px'
                  p='.5rem .75rem'
                  backdropFilter='blur(5px)'
                >
                  <ProjectNavigationButtons
                    selectedValue={selectedNavigation}
                  />
                </Box>
              </Flex> */}
          </Container>
        </Box>
      </>

      <Container maxW='1600px' width='100%' mt='-18rem'>
        <Box>
          {!isLoading && !projectRes.data?.verified && (
            <Alert colorScheme={'yellow'} status='warning' rounded='10px'>
              <AlertIcon />
              <AlertTitle>
                <Text>Heads up:</Text>
              </AlertTitle>
              <AlertDescription>
                <HStack>
                  <Text>This community is unverified.</Text>
                  <Link
                    href='https://sac-nft.notion.site/MONET-Unverified-Community-9e54516c2d274c6d85e236331865ac15'
                    target='_blank'
                  >
                    <Text>Learn more about what that means.</Text>
                  </Link>
                </HStack>
              </AlertDescription>
            </Alert>
          )}

          <Box marginTop='1.2rem' marginBottom='5rem'>
            <ProfileCard variant='project' />
          </Box>

          <ContentTabs
            tabs={communityPageTabs.map(t => t.label)}
            activeTab={selectedNavigation?.label}
            setActiveTab={(tab) => {
              const tabToSelect = communityPageTabs.find(t => t.label === tab)
              if (tabToSelect) {
                history.replaceState(null, '', '#' + tabToSelect.value)
                setSelectedNavigation(tabToSelect)
              }
            }}
          />
          <Box mt='50px'>
            {selectedNavigation?.value === 'INFO' && <ProjectInfo />}

            {selectedNavigation?.value === 'RAFFLES' && (
              <>
                {projectStatRes.data && (
                  <Stack
                    my='3rem'
                    gap='3'
                    flexDirection={['column', 'row', 'row', 'row']}
                    flexWrap='wrap'
                  >
                    <StatCard
                      Icon={PiLightning}
                      title='Raffles Created'
                      number={projectStatRes.data.createdRaffles}
                      variant='project'
                    />
                    <StatCard
                      variant='project'
                      Icon={HiOutlineTicket}
                      title='Tickets Sold'
                      number={projectStatRes.data.ticketsSold}
                    />

                    <StatCard
                      Icon={PiStackSimple}
                      path='/images/rewards/coinSwapIcon.svg'
                      variant='project'
                      title='Raffle Volume'
                      number={`${formatFloatForDisplay(projectStatRes.data.totalVolume)} SOL`}
                    />
                    <StatCard
                      variant='project'
                      Icon={PiUsers}
                      title='Members'
                      number={projectStatRes.data.holders}
                    />

                    <StatCard
                      variant='project'
                      Icon={PiStar}
                      title='Community Tags'
                      number={projectStatRes.data.communityTagCount}
                    />
                  </Stack>
                )}
                <ProjectOverviewRaffles />
              </>
            )}

            {selectedNavigation?.value === 'MEMBERS' && <ProjectCommunityMembers />}
            {selectedNavigation?.value === 'OTC' && <ProjectOTCDeals />}


          </Box>
        </Box>
        <Footer />
      </Container>
    </Box>
  )
}

export default DAppCommunityOverview

const ProjectOverviewHeaderSkeleton = () => (
  <Box
    height={['30rem']}
    w='100%'
    bgRepeat='no-repeat'
    bgSize='cover'
    background={`linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('')`}
    bgPos='center top'
    backgroundSize='cover!important'
    px='1rem'
    py={['.5rem', '1rem', '2rem']}
  >
    <Container maxWidth='1440px'>
      <Header theme='transparent' />
      <Stack
        direction={['column', 'column', 'row', 'row']}
        mt={['4rem', '6.5rem']}
        justify='space-between'
        spacing={[5, 10, 0, 0]}
      >
        <HStack color='#fff' spacing={'1.25rem'}>
          <SkeletonCircle w='4rem' h='4rem' />
          <Skeleton w='10rem' h='40px' />
        </HStack>
        <Stack direction='row' spacing={[5, 5, 7]}>
          <SkeletonCircle h='25px' w='25px' />
          <SkeletonCircle h='25px' w='25px' />
          <SkeletonCircle h='25px' w='25px' />
          <SkeletonCircle h='25px' w='25px' />
        </Stack>
      </Stack>
      <Stack
        direction={['column', 'row']}
        mt={['2rem', '2rem', '5.75rem']}
        gap='1rem'
      >
        <Skeleton w='16rem' h='2.8rem' rounded='20px' />
      </Stack>
    </Container>
  </Box>
)
