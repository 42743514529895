import { Box, Spinner, Stack, Text, useColorMode } from '@chakra-ui/react'
import OTCDealCardList from '../../otcDeals/components/OTCDealCardList'
import { useAllOTCDealsForProject } from '../../otcDeals/hooks/otcDealHooks'
import { useProjectId } from '../../techRaffles/hooks/project'

export default function ProjectOTCDeals() {
  const projectId = useProjectId() || ''
  const otcDealsRes = useAllOTCDealsForProject(projectId, 'OPEN')
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  return (
    <Box>
      <Stack spacing='1rem'>
        <Text fontSize='1.875rem' fontWeight='600'>
          Running OTC Deals
        </Text>
        {otcDealsRes.isLoading && (
          <Spinner color={isDarkMode ? 'white' : 'black'}></Spinner>
        )}
        <OTCDealCardList otcDeals={otcDealsRes.data ?? []} />
        {otcDealsRes.data?.length === 0 && !otcDealsRes.isLoading && (
          <Text>No OTC Deals running.</Text>
        )}
      </Stack>
    </Box>
  )
}
